import { render, staticRenderFns } from "./feature.vue?vue&type=template&id=42f4bcef&scoped=true&"
import script from "./feature.vue?vue&type=script&lang=ts&"
export * from "./feature.vue?vue&type=script&lang=ts&"
import style0 from "./feature.vue?vue&type=style&index=0&id=42f4bcef&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42f4bcef",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VCard,VCol,VContainer,VHover,VImg,VMain,VRow,VSheet})
