














































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
//import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'printer',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    //'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = 'あなたに最適な一台はこれ！プリンター選びのポイント | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '高画質プリントで写真印刷や年賀状もプロ級の仕上がりに！宛名印刷にも役立つ便利で手軽に使えるプリンターをカメラのキタムラがご紹介します'
      );
    const state = reactive({
      recommendedFeaturesList: [
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/camera/album',
          img: 'https://shop.kitamura.jp/images/banner/2456.gif',
          alt: 'おすすめ写真アルバム紹介'
        },
        {
          href: 'https://shop.kitamura.jp/special/sale-fair/camera/album/feature/2519/',
          img: 'https://shop.kitamura.jp/images/banner/3496.jpg',
          alt: '年賀状のデコレーションにおすすめアイテムをご紹介！写真ペン'
        },
        {
          href: '/ec/special/camera/photoprinter',
          img: 'https://shop.kitamura.jp/images/banner/9718.png',
          alt: 'おすすめフォトプリンター特集'
        },
        {
          href: '/ec/special/camera/filmcamera',
          img: 'https://shop.kitamura.jp/images/banner/3771.gif',
          alt: 'チェキ・チェキフィルム、チェキアルバムおすすめ商品'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'おすすめプリンター特集',
          linkUrl: '/ec/special/camera/printer',
          disabled: false
        }
      ],
      // 取得するJancode 4549292157345 4549292157512
      productJanCodeList: ['4549292237771'],
      productDetailList: [] as Array<ProductDetail>, // 結果格納用 写真印刷1
      productJanCodeList2: ['4988617516021'],
      productDetailList2: [] as Array<ProductDetail>, // 結果格納用 写真印刷2
      productJanCodeList3: ['4549292143324'],
      productDetailList3: [] as Array<ProductDetail>, // 結果格納用 ランキング1
      productJanCodeList4: ['4549292237412'],
      productDetailList4: [] as Array<ProductDetail>, // 結果格納用 ランキング2
      productJanCodeList5: ['4549292218367'],
      productDetailList5: [] as Array<ProductDetail>, // 結果格納用 ランキング3
      productJanCodeList6: ['4988617369344'],
      productDetailList6: [] as Array<ProductDetail> // 結果格納用 ランキング4
      // ↑ 取得するJancode
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
        const productListResult2 = await ProductService.fetchProducts(state.productJanCodeList2, true);
        state.productDetailList2 = productListResult2.items;
        const productListResult3 = await ProductService.fetchProducts(state.productJanCodeList3, true);
        state.productDetailList3 = productListResult3.items;
        const productListResult4 = await ProductService.fetchProducts(state.productJanCodeList4, true);
        state.productDetailList4 = productListResult4.items;
        const productListResult5 = await ProductService.fetchProducts(state.productJanCodeList5, true);
        state.productDetailList5 = productListResult5.items;
        const productListResult6 = await ProductService.fetchProducts(state.productJanCodeList6, true);
        state.productDetailList6 = productListResult6.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
        state.productDetailList2 = [] as Array<ProductDetail>;
        state.productDetailList3 = [] as Array<ProductDetail>;
        state.productDetailList4 = [] as Array<ProductDetail>;
        state.productDetailList5 = [] as Array<ProductDetail>;
        state.productDetailList6 = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
