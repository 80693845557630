import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import VueMeta from 'vue-meta';
import VueGtm from 'vue-gtm';
import { equalsHostname, getLocationRemovedQuery, isDebugMode, isLocalHost, isBot } from '@/logic/utils';
import AuthService from '@/logic/auth.service';
import GtmService from '@/logic/gtm.service';
import CanonicalService from '@/logic/canonical.service';
import Cookie from '@/logic/cookie';
import MyPageRoutes from './mypage-routes';

// GuidePageでデザインが崩れるため、遅延ローディング対象外とする
import SpecialPage from '../views/special/special-page.vue';

// 遅延ローディング
const TopPage = () => import(/* webpackChunkName: "TopPage" */ '../views/top-page.vue');
const TopPage2 = () => import(/* webpackChunkName: "TopPage2" */ '../views/top-page2.vue');
const ProductListPage = () => import(/* webpackChunkName: "ProductListPage" */ '../views/product/product-list-page.vue');
const ProductDetailPage = () => import(/* webpackChunkName: "ProductDetailPage" */ '../views/product/product-detail-page.vue');
const ProductComparePage = () => import(/* webpackChunkName: "ProductComparePage" */ '../views/product/product-compare-page.vue');
const UsedProductCategoryListPage = () => import(/* webpackChunkName: "UsedProductCategoryListPage2" */ '../views/product/used-category-list-page.vue');
const MyPage = () => import(/* webpackChunkName: "MyPage" */ '../views/my-page.vue');
const HtmlPage = () => import(/* webpackChunkName: "HtmlPage" */ '../views/static/html-page.vue');
const NotFoundPage = () => import(/* webpackChunkName: "NotFoundPage" */ '../views/not-found-page.vue');

// 遅延ローディング[SpecialPage]
const SpecialSalePage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/special-sale-page.vue');
const SpecialNewsPage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/special-news-page.vue');
const SpecialCameraFramePage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/camera/frame-page.vue');
const SpecialCameraAlbumPage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/camera/album-page.vue');
const SpecialCameraPhotomountPage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/camera/photomount-page.vue');
const SpecialCameraPrinterPage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/camera/printer-page.vue');
const SpecialCameraCouponPage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/camera/usedcameracoupon-page.vue');
const SpecialCameraSalePage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/camera/usedcamerasale-page.vue');
// const SpecialCameraBagFeaturePage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/camera/bag-feature-page.vue');
const AlbumFeaturePage = () => import(/* webpackChunkName: "SpecialPage" */ '@/components/special/camera/album/feature.vue');
const FrameShacollaPage = () => import(/* webpackChunkName: "SpecialPage" */ '@/components/special/camera/frame/shacolla.vue');
const FrameOrdermadeaPage = () => import(/* webpackChunkName: "SpecialPage" */ '@/components/special/camera/frame/ordermade.vue');
const CorkFramePage = () => import(/* webpackChunkName: "SpecialPage" */ '@/components/special/camera/frame/cork-frame.vue');
const SapelePage = () => import(/* webpackChunkName: "SpecialPage" */ '@/components/special/camera/frame/sapele.vue');
const SpecialMomijigariPage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/camera/momijigari-page.vue');
const SpecialHarunokourakuPage = () => import(/* webpackChunkName: "SpecialPage" */ '../views/special/camera/harunokouraku-page.vue');

// 遅延ローディング[NewsPage]
const NewsListPage = () => import(/* webpackChunkName: "NewsPage" */ '../views/news/news-list-page.vue');
const NewsDetailPage = () => import(/* webpackChunkName: "NewsPage" */ '../views/news/news-detail-page.vue');

// 遅延ローディング[ReviewPage]
const ReviewListPage = () => import(/* webpackChunkName: "ReviewPage" */ '../views/review/review-list-page.vue');
const ReviewDetailPage = () => import(/* webpackChunkName: "ReviewPage" */ '../views/review/review-detail-page.vue');

// 遅延ローディング[GuidePage]
const GuideMsupportPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/m-support.vue');
const GuideServiceGuidePage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/service-guide.vue');
const GuideFirstGuidePage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/firstguide.vue');
const GuideRecyclePage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/recycle.vue');
const GuideSetupTelevisionPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/setup/television.vue');
const GuideSetupRefrigeratorPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/setup/refrigerator.vue');
const GuideSetupWasherPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/setup/washer.vue');
const GuideSetupAirConditionerPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/setup/air-conditioner.vue');
const GuideSetupAirConditionerFaqPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/setup/air-conditioner-faq.vue');
const GuideSetupAirConditionerOptionPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/setup/air-conditioner-option.vue');
const GuideMyPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/mypage.vue');
const GuideSyuuriPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/syuuri.vue');
const GuideSyuuriOnlinePage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/syuuri/online.vue');
const GuideSyuuriTenpoPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/syuuri/tenpo.vue');
const GuideCreditCardPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/credit_card.vue');
const GuideFaqTokutokukoukanPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/faq_tokutokukoukan.vue');
const GuideHoshouPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/hoshou.vue');
const GuideShiharaiPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/shiharai.vue');
const GuideKakakuPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/kakaku.vue');
const GuideShitadoriPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/shitadori.vue');
const GuideScredit01Page = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/s_credit_01.vue');
const GuideSyuuriOnlineQandAPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/syuuri/online/syuuriQ&A.vue');
const GuideSyuuriTenpoQandAPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/syuuri/tenpo/syuuriQ&A.vue');
const GuideSyuuriSyouhinQandAPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/syuuri/syouhinQ&A.vue');
const GuideRiyouKaiinPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/riyou_kaiin.vue');
const GuideRiyouKaiinkakakuPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/riyou_kaiinkakaku.vue');
const Guide3DsecurePage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/3Dsecure.vue');
const GuidePromente = () => import(/* webpackChunkName: "Promente" */ '../views/guide/promente.vue');
const GuideRenrakuPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/renraku.vue');
const GuideSouryouPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/souryou.vue');
const GuideOtodokePage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/otodoke.vue');
const GuideCancelPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/cancel.vue');
const GuideUsedhoshouPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/used_hoshou.vue');
const GuideHoshouLinePage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/hoshou_line.vue');
const GuideRyoushuushoPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/ryoushuusho.vue');
const GuideSecurityPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/security.vue');
const GuideHenpinPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/henpin.vue');
const GuideAfterservicePage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/afterservice.vue');
const GuideLargesizekadenPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/large-size_kaden.vue');
const GuideAtokaraPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/atokara.vue');
const GuideMemberInformationFaqPage = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/member_information_faq.vue');
const GuideSecurityPagephone = () => import(/* webpackChunkName: "GuidePage" */ '../views/guide/security/phone_verification.vue');
// 遅延ローディング[CartPage]
const CartListPage = () => import(/* webpackChunkName: "CartPage" */ '../views/cart/cart-list-page.vue');
const OrderPrecheckPage = () => import(/* webpackChunkName: "CartPage" */ '../views/cart/order-precheck-page.vue');
const OrderPage = () => import(/* webpackChunkName: "CartPage" */ '../views/cart/order-page.vue');
const OrderCompletePage = () => import(/* webpackChunkName: "CartPage" */ '../views/cart/order-complete-page.vue');
const ShoppingCreditCompletePage = () => import(/* webpackChunkName: "CartPage" */ '../views/cart/shopping-credit-complete-page.vue');
const orderAtokaraPage = () => import(/* webpackChunkName: "CartPage" */ '../views/cart/order-atokara-page.vue');
const OrderConfirmPage = () => import(/* webpackChunkName: "CartPage" */ '../views/cart/order-confirm-page.vue');
const OrderErrorPage = () => import(/* webpackChunkName: "CartPage" */ '../views/cart/order-error-page.vue');
const OrderFraudulentErrorPage = () => import(/* webpackChunkName: "CartPage" */ '../views/cart/order-fraudulent-error-page.vue');

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'top-page',
    meta: { gtm: 'TopPage' },
    component: TopPage
  },
  {
    path: '/ec/top2',
    name: 'top-page2',
    meta: { gtm: 'TopPage2' },
    component: TopPage2
  },
  {
    path: '/ec/ct/used/list',
    name: 'used-category-list-page',
    meta: { gtm: 'UsedProductCategoryListPage', showFooterUsedCategory: true },
    component: UsedProductCategoryListPage
  },
  {
    path: '/ec/list',
    name: 'product-list-page',
    meta: { gtm: 'ProductListPage' },
    component: ProductListPage
  },
  {
    path: '/ec/ct/:code([0-9a-zA-Z/]+)',
    name: 'product-category-page',
    meta: { gtm: 'ProductListPage' },
    component: ProductListPage
  },
  {
    path: '/ec/pd/compareproducts',
    name: 'product-compare-page',
    meta: { gtm: 'ProductComparePage' },
    component: ProductComparePage
  },
  {
    path: '/ec/pd/:id',
    name: 'product-detail-page',
    meta: {
      gtm: 'ProductDetailPage',
      showTabMenu: false,
      showBackToTopButton: false
    },
    component: ProductDetailPage
  },
  {
    path: '/ec/used/:id',
    name: 'product-used-detail-page',
    meta: {
      gtm: 'ProductDetailPage',
      showTabMenu: false,
      showBackToTopButton: false
    },
    component: ProductDetailPage
  },
  {
    path: '/ec/review/:janCode',
    name: 'review-list-page',
    meta: { gtm: 'ReviewListPage' },
    component: ReviewListPage
  },
  {
    path: '/ec/review/:janCode/:reviewId',
    name: 'review-detail-page',
    meta: { gtm: 'ReviewDetailPage' },
    component: ReviewDetailPage
  },
  {
    path: '/ec/guide/recycle',
    name: 'guide-recycle-page',
    meta: { gtm: 'GuidePage' },
    component: GuideRecyclePage
  },
  {
    path: '/ec/guide/firstguide',
    name: 'guide-first-guide-page',
    meta: { gtm: 'GuidePage' },
    component: GuideFirstGuidePage
  },
  {
    path: '/ec/guide/service-guide',
    name: 'guide-service-guide-page',
    meta: { gtm: 'GuidePage' },
    component: GuideServiceGuidePage
  },
  {
    path: '/ec/guide/m_support',
    name: 'guide-m-support-page',
    meta: { gtm: 'GuidePage' },
    component: GuideMsupportPage
  },
  {
    path: '/ec/guide/setup/television',
    name: 'guide-setup-television-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSetupTelevisionPage
  },
  {
    path: '/ec/guide/setup/refrigerator',
    name: 'guide-setup-refrigerator-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSetupRefrigeratorPage
  },
  {
    path: '/ec/guide/setup/washer',
    name: 'guide-setup-washer-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSetupWasherPage
  },
  {
    path: '/ec/guide/setup/air-conditioner',
    name: 'guide-setup-air-conditioner-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSetupAirConditionerPage
  },
  {
    path: '/ec/guide/setup/air-conditioner-faq',
    name: 'guide-setup-air-conditioner-faq-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSetupAirConditionerFaqPage
  },
  {
    path: '/ec/guide/setup/air-conditioner-option',
    name: 'guide-setup-air-conditioner-option-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSetupAirConditionerOptionPage
  },
  {
    path: '/ec/guide/mypage',
    name: 'guide-my-page',
    meta: { gtm: 'GuidePage' },
    component: GuideMyPage
  },
  {
    path: '/ec/guide/syuuri',
    name: 'guide-syuuri-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSyuuriPage
  },
  {
    path: '/ec/guide/syuuri/online',
    name: 'guide-syuuri-online-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSyuuriOnlinePage
  },
  {
    path: '/ec/guide/syuuri/tenpo',
    name: 'guide-syuuri-tenpo-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSyuuriTenpoPage
  },
  {
    path: '/ec/guide/credit_card',
    name: 'guide-credit-card-page',
    meta: { gtm: 'GuidePage' },
    component: GuideCreditCardPage
  },
  {
    path: '/ec/guide/faq_tokutokukoukan',
    name: 'guide-faq-tokutokukoukan-page',
    meta: { gtm: 'GuidePage' },
    component: GuideFaqTokutokukoukanPage
  },
  {
    path: '/ec/guide/hoshou',
    name: 'guide-hoshou-page',
    meta: { gtm: 'GuidePage' },
    component: GuideHoshouPage
  },
  {
    path: '/ec/guide/shiharai',
    name: 'guide-shiharai-page',
    meta: { gtm: 'GuidePage' },
    component: GuideShiharaiPage
  },
  {
    path: '/ec/guide/kakaku',
    name: 'guide-kakaku-page',
    meta: { gtm: 'GuidePage' },
    component: GuideKakakuPage
  },
  {
    path: '/ec/guide/shitadori',
    name: 'guide-shitadori-page',
    meta: { gtm: 'GuidePage' },
    component: GuideShitadoriPage
  },
  {
    path: '/ec/guide/s_credit_01',
    name: 'guide-s-credit-01-page',
    meta: { gtm: 'GuidePage' },
    component: GuideScredit01Page
  },
  {
    path: '/ec/guide/syuuri/online/syuuriQ&A',
    name: 'guide-syuuri-online-syuuriQ&A-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSyuuriOnlineQandAPage
  },
  {
    path: '/ec/guide/syuuri/tenpo/syuuriQ&A',
    name: 'guide-syuuri-tenpo-syuuriQ&A-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSyuuriTenpoQandAPage
  },
  {
    path: '/ec/guide/syuuri/syouhinQ&A',
    name: 'guide-syuuri-syouhinQ&A-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSyuuriSyouhinQandAPage
  },
  {
    path: '/ec/guide/riyou_kaiin',
    name: 'guide-riyou-kaiin-page',
    meta: { gtm: 'GuidePage' },
    component: GuideRiyouKaiinPage
  },
  {
    path: '/ec/guide/riyou_kaiinkakaku',
    name: 'guide-riyou_kaiinkakaku-page',
    meta: { gtm: 'GuidePage' },
    component: GuideRiyouKaiinkakakuPage
  },
  {
    path: '/ec/guide/3Dsecure',
    name: 'guide-3Dsecure-page',
    meta: { gtm: 'GuidePage' },
    component: Guide3DsecurePage
  },
  {
    path: '/ec/guide/promente',
    name: 'guide-promente',
    meta: { gtm: 'GuidePage' },
    component: GuidePromente
  },
  {
    path: '/ec/guide/renraku',
    name: 'guide-renraku-page',
    meta: { gtm: 'GuidePage' },
    component: GuideRenrakuPage
  },
  {
    path: '/ec/guide/souryou',
    name: 'guide-souryou-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSouryouPage
  },
  {
    path: '/ec/guide/otodoke',
    name: 'guide-otodoke-page',
    meta: { gtm: 'GuidePage' },
    component: GuideOtodokePage
  },
  {
    path: '/ec/guide/cancel',
    name: 'guide-cancel-page',
    meta: { gtm: 'GuidePage' },
    component: GuideCancelPage
  },
  {
    path: '/ec/guide/used_hoshou',
    name: 'guide-used_hoshou-page',
    meta: { gtm: 'GuidePage' },
    component: GuideUsedhoshouPage
  },
  {
    path: '/ec/guide/hoshou_line',
    name: 'guide-hoshou_line-page',
    meta: { gtm: 'GuidePage' },
    component: GuideHoshouLinePage
  },
  {
    path: '/ec/guide/ryoushuusho',
    name: 'guide-ryoushuusho-page',
    meta: { gtm: 'GuidePage' },
    component: GuideRyoushuushoPage
  },
  {
    path: '/ec/guide/security',
    name: 'guide-security-page',
    meta: { gtm: 'GuidePage' },
    component: GuideSecurityPage
  },
  {
    path: '/ec/guide/henpin',
    name: 'guide-henpin-page',
    meta: { gtm: 'GuidePage' },
    component: GuideHenpinPage
  },
  {
    path: '/ec/guide/afterservice',
    name: 'guide-afterservice-page',
    meta: { gtm: 'GuidePage' },
    component: GuideAfterservicePage
  },
  {
    path: '/ec/guide/large-size_kaden',
    name: 'guide-large-size_kaden-page',
    meta: { gtm: 'GuidePage' },
    component: GuideLargesizekadenPage
  },
  {
    path: '/ec/guide/atokara',
    name: 'guide-atokara-page',
    meta: { gtm: 'GuidePage' },
    component: GuideAtokaraPage
  },
  {
    path: '/ec/guide/security/phone_verification',
    name: 'guide-security-pagephone',
    meta: { gtm: 'GuidePage' },
    component: GuideSecurityPagephone
  },
  {
    path: '/ec/guide/member_information_faq',
    name: 'guide-member_information_faq-page',
    meta: { gtm: 'GuidePage' },
    component: GuideMemberInformationFaqPage
  },
  {
    path: '/ec/guide/:page',
    name: 'guide-html-page',
    meta: { gtm: 'GuidePage' },
    component: HtmlPage
  },
  {
    path: '/ec/sale/:name',
    name: 'special-sale-page',
    meta: { gtm: 'SpecialSalePage' },
    component: SpecialSalePage
  },
  {
    path: '/ec/special/news',
    name: 'special-news-page',
    meta: { gtm: 'SpecialNewsPage' },
    component: SpecialNewsPage
  },
  {
    path: '/ec/special/limitedSalesPage',
    name: 'special-news-page',
    meta: { gtm: 'SpecialNewsPage' },
    component: SpecialNewsPage
  },
  {
    path: '/ec/special/camera/frame/shacolla',
    name: 'camera-frame-page',
    meta: { gtm: 'FrameShacollaPage' },
    component: FrameShacollaPage
  },
  {
    path: '/ec/special/camera/frame/ordermade',
    name: 'camera-frame-page',
    meta: { gtm: 'FrameOrdermadeaPage' },
    component: FrameOrdermadeaPage
  },
  {
    path: '/ec/special/camera/frame/cork-frame',
    name: 'camera-frame-page',
    meta: { gtm: 'CorkFramePage' },
    component: CorkFramePage
  },
  {
    path: '/ec/special/camera/frame/sapele',
    name: 'camera-frame-page',
    meta: { gtm: 'SapelePage' },
    component: SapelePage
  },
  {
    path: '/ec/special/camera/frame/:id',
    name: 'camera-frame-page',
    meta: { gtm: 'SpecialCameraFramePage' },
    component: SpecialCameraFramePage
  },
  {
    path: '/ec/special/camera/album/feature',
    name: 'camera-album-page',
    meta: { gtm: 'AlbumFeaturePage' },
    component: AlbumFeaturePage
  },
  {
    path: '/ec/special/camera/album/:id',
    name: 'camera-album-page',
    meta: { gtm: 'SpecialCameraAlbumPage' },
    component: SpecialCameraAlbumPage
  },
  {
    path: '/ec/special/camera/photomount/:id',
    name: 'camera-photomount-page',
    meta: { gtm: 'SpecialCameraPhotomountPage' },
    component: SpecialCameraPhotomountPage
  },
  {
    path: '/ec/special/camera/printer/:id',
    name: 'camera-printer-page',
    meta: { gtm: 'SpecialCameraPrinterPage' },
    component: SpecialCameraPrinterPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/canon/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/nikon/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/fujifilm/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/sony/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/olympus/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/panasonic/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/pentax/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/leica/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/tamron/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/sigma/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/other/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedcameracoupon/zeiss/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraCouponPage' },
    component: SpecialCameraCouponPage
  },
  {
    path: '/ec/special/sale/usedsale/canon/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/nikon/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/fujifilm/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/sony/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/olympus/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/panasonic/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/pentax/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/leica/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/tamron/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/sigma/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/other/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/zeiss/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/minolta/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/contax/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/sale/usedsale/mamiya/:id',
    name: 'camera-coupon-page',
    meta: { gtm: 'SpecialCameraSalePage' },
    component: SpecialCameraSalePage
  },
  {
    path: '/ec/special/camera/momijigari/:id',
    name: 'momijigari-page',
    meta: { gtm: 'SpecialMomijigariPage' },
    component: SpecialMomijigariPage
  },
  {
    path: '/ec/special/camera/harunokouraku/:id',
    name: 'harunokouraku-page',
    meta: { gtm: 'SpecialHarunokourakuPage' },
    component: SpecialHarunokourakuPage
  },
  //{
  //  path: '/ec/special/camera/bag/feature/:id',
  //  name: 'camera-bag-feature-page',
  //  meta: { gtm: 'SpecialCameraBagFeaturePage' },
  //  component: SpecialCameraBagFeaturePage
  //},
  {
    path: '/ec/special/*',
    name: 'special-page',
    meta: { gtm: 'SpecialPage' },
    component: SpecialPage
  },
  {
    path: '/ec/news',
    name: 'news-list-page',
    meta: { gtm: 'NewsListPage' },
    component: NewsListPage
  },
  {
    path: '/ec/news/:year',
    name: 'news-list-year-page',
    meta: { gtm: 'NewsListPage' },
    component: NewsListPage
  },
  {
    path: '/ec/news/detail/:id',
    name: 'news-detail-page',
    meta: { gtm: 'NewsDetailPage' },
    component: NewsDetailPage
  },
  {
    path: '/ec/mypage',
    component: MyPage,
    children: [...MyPageRoutes]
  },
  {
    path: '/ec/cart',
    name: 'cart-list-page',
    meta: {
      gtm: 'CartListPage',
      showTabMenu: false,
      showSearchMenu: false
    },
    component: CartListPage
  },
  {
    path: '/ec/order',
    name: 'order-page',
    meta: {
      gtm: 'OrderPage',
      simpleHeader: true,
      fixedHeader: false,
      simpleFooter: true
    },
    component: OrderPage
  },
  {
    // order-pageと同じpathだが、ルーティング設定で切り替わる
    path: '/ec/order',
    name: 'order-precheck-page',
    meta: {
      gtm: 'OrderPrecheckPage',
      simpleHeader: true,
      simpleFooter: true
    },
    component: OrderPrecheckPage
  },
  {
    // order-pageと同じpathだが、ルーティング設定で切り替わる
    path: '/ec/order',
    name: 'order-confirm-page',
    meta: {
      gtm: 'OrderConfirmPage',
      simpleHeader: true,
      fixedHeader: false,
      simpleFooter: true
    },
    component: OrderConfirmPage
  },
  {
    path: '/ec/orderComp',
    name: 'order-complete-page',
    meta: { gtm: 'OrderCompletePage' },
    component: OrderCompletePage
  },
  {
    path: '/ec/FraudulentError',
    name: 'order-fraudulent-error-page',
    meta: { gtm: 'OrderFraudulentErrorPage' },
    component: OrderFraudulentErrorPage
  },
  {
    path: '/ec/orderError',
    name: 'order-error-page',
    meta: { gtm: 'OrderErrorPage' },
    component: OrderErrorPage
  },
  {
    path: '/ec/orderShoppingCredit',
    name: 'order-shopping-credit-page',
    meta: { gtm: 'orderShoppingCredit' },
    component: ShoppingCreditCompletePage
  },
  {
    path: '/ec/orderAtokara',
    name: 'order-atokara-page',
    meta: { gtm: 'orderAtokara', simpleHeader: true, fixedHeader: false, simpleFooter: true },
    component: orderAtokaraPage
  },
  {
    path: '*',
    name: 'not-found-page',
    component: NotFoundPage
  },
  {
    path: '/ec/guide/tablet/:page',
    name: 'guide-tablet-html-page',
    meta: { gtm: 'GuidePage' },
    component: HtmlPage
  }
];

const SFTAB_KEY = '__sftab_shop_login';

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    // 画面遷移時にページ上部に移動する
    return { x: 0, y: 0 };
  }
});

// vue-gtm設定
Vue.use(VueGtm, {
  // GTM ID設定
  id: 'GTM-T6TZ94D',
  // プラグイン有効
  enabled: true,
  // デバックモードON
  debug: isDebugMode(),
  // GTMスクリプトロード
  loadScript: true,
  // ルーターと同期
  vueRouter: router
});

/**
 * GTMのトラッキングイベントを発火する
 * ルーティング前に発火させる必要がある
 * @param route ルーティング
 */
const _trackEvent = async (route: Route): Promise<void> => {
  const gtmPageName = route.meta.gtm;
  if (gtmPageName) {
    if (!window.google_tag_manager) {
      // まだGTMの定義がされいない場合、待機する
      await GtmService.waitInitGtm();
    }
    const janCode = route.params.id;
    const netMemberId = Vue.prototype.$store.authorizer.user?.netMemberId || AuthService.getSession();
    const referrer = route.meta?.gtmAdditionalEventData?.gtm?.oldUrl;
    GtmService.trackEventForGa(gtmPageName, janCode, netMemberId, referrer);
  }
};

// ルーティング前
router.beforeEach(async (to, from, next) => {
  // 全体ローディングを表示にする
  Vue.prototype.$store.loader.routeUnset();

  // headタグを初期化する
  CanonicalService.clear();

  // 商品詳細ページ用
  if (to.name === 'product-detail-page') {
    const sftabVal = Cookie.getCookieBooleanValue(SFTAB_KEY);
    if (sftabVal) {
      window.location.href = `/sftab/prd.html?janCode=${to.params.id || ''}`;
      return;
    }
  }

  // 中古商品詳細ページ用
  if (to.name === 'product-used-detail-page') {
    const sftabVal = Cookie.getCookieBooleanValue(SFTAB_KEY);
    if (sftabVal) {
      window.location.href = `/sftab/prdUsed.html?itemCode=${to.params.id || ''}`;
      return;
    }
  }

  // カートページ用
  if (to.name === 'cart-list-page') {
    const sftabVal = Cookie.getCookieBooleanValue(SFTAB_KEY);
    if (sftabVal) {
      window.location.href = '/sftab/cart.html';
      return;
    }
  }

  // 比較ページ用
  if (to.name === 'product-compare-page' && to.query.prd) {
    // JanCodeソート
    const janCodes = to.query.prd as string;
    const sortJancodes = janCodes
      .split('-')
      .sort()
      .join('-');

    if (janCodes !== sortJancodes) {
      next({ name: 'product-compare-page', query: { prd: sortJancodes } });
      return;
    }
  }
  // レジページはクエリパラメータによって遷移先を変更する
  else if (to.name === 'order-page' && to.query.step === 'precheck') {
    next({ name: 'order-precheck-page', query: to.query });
    return;
  } else if (to.name === 'order-page' && to.query.step === 'confirm') {
    next({ name: 'order-confirm-page', query: to.query });
    return;
  } else if (to.name === 'order-precheck-page' && to.query.step !== 'precheck') {
    to.query.step = 'precheck';
    next({ name: 'order-precheck-page', query: to.query });
    return;
  } else if (to.name === 'order-confirm-page' && to.query.step !== 'confirm') {
    to.query.step = 'confirm';
    next({ name: 'order-confirm-page', query: to.query });
    return;
  }

  if (isLocalHost() || equalsHostname(process.env.VUE_APP_SSO_SKIP_HOSTNAME)) {
    /**
     * ローカルホストまたは特定ドメインの場合APIがCORSエラーとなりリダイレクトを繰り返す可能性があるため、
     * SSO対応をスキップする
     */
    console.warn('This page url is localhost or specific domain. So, sso is skip.');

    // GTMイベント
    await _trackEvent(to);

    next();
    return;
  }

  let toUrl = location.origin + to.fullPath;
  AuthService.initLoginStatus();

  // ボットの場合、SSOはスキップする
  const userAgent = window.navigator.userAgent;
  if (to.query.needsso && isBot(userAgent)) {
    console.warn('User-Agent of Bot was detected. User-Agent:', userAgent);
    delete to.query.needsso;
  }

  let nextTo = to;
  let token: string | null = null;
  const queryToken = to.query.token;

  // クエリパラメータにTokenが複数ある場合、一番最後の値を取得する
  if (queryToken && Array.isArray(queryToken)) {
    token = queryToken.length ? queryToken[queryToken.length - 1] : null;
  } else {
    token = queryToken;
  }

  // ハッシュ付きURLの場合、Tokenがto.queryで取得できないため、フルパスから取得する
  if (to.hash && to.hash.search('token') !== -1) {
    token = to.hash.split('token')[1].replace('=', '');
    nextTo = {
      path: to.path,
      name: to.name,
      hash: to.hash.split('?')[0],
      query: to.query,
      params: to.params,
      fullPath: to.fullPath.replace('?token=' + token, ''),
      matched: to.matched,
      redirectedFrom: to.redirectedFrom,
      meta: to.meta || {}
    };
  }

  const queryReferer = to.query.r;
  if (to.query.needsso) {
    // 無限ループしてしまうため、needssoのクエリストリングを削除する
    if (to.query.needsso) {
      // 複数のクエリストリングがある場合
      if (Object.keys(to.query).length > 1) {
        toUrl = toUrl.replace('&needsso=true', '');
        toUrl = toUrl.replace('needsso=true&', '');
      } else {
        toUrl = toUrl.replace('?needsso=true', '');
      }
      delete to.query.needsso;
    }

    // リダイレクトによりリファラ情報が消えてしまうことを防ぐためにクエリパラメータに追加する
    const referrer = document.referrer;
    if (!queryReferer && !!referrer) {
      toUrl += to.query && !!Object.keys(to.query).length ? '&' : '?';
      toUrl += `r=${referrer}`;
    }

    // 引継ぎトークンもセッション情報もない場合、トークンを発行する
    // リダイレクトが発生する
    AuthService.createToken(toUrl);
  } else if (token) {
    // 引継ぎトークンが渡ってきた場合、セッション情報を取得する
    await AuthService.requestSession(token, toUrl);

    // 引継ぎトークン情報を削除する
    // お気に入り登録の際にtokenパラメータを入れた状態にしないため
    const nextLocation = getLocationRemovedQuery(nextTo, ['token', 'status']);
    next(nextLocation);
  } else if (queryReferer) {
    // GTMにリファラを付与
    nextTo.meta.gtmAdditionalEventData = { gtm: { oldUrl: queryReferer } };
    const nextLocation = getLocationRemovedQuery(nextTo, ['r']);
    next(nextLocation);
  } else {
    // GTMイベント
    await _trackEvent(to);

    // セッション情報が既にあるため、SSO対応は不要
    next();
  }
});

// ルーティング後
router.afterEach(async (to) => {
  // 全体ローディングを非表示にする
  Vue.prototype.$store.loader.routeLoaded();

  // GTM用のリファラ情報を削除
  delete to.meta.gtmAdditionalEventData?.gtm?.oldUrl;
});

export default router;
