

















































































































































































































import Vue from 'vue';
import { computed, reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import Urlcopy from '@/components/common/special/url-copy.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ShoppingCredit from '@/components/common/special/shopping-credit.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'girlscamera',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    shoppingCredit: ShoppingCredit,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '心ときめくフォトジェニックな瞬間に！きゅんとくるミラーレス一眼カメラ特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '旅行やお出掛けにも持ち運びできる、軽くてコンパクトな人気のミラーレスカメラ☆心ときめくフォトジェニックな瞬間が撮影できるおすすめの機種をピックアップしました！かわいくておしゃれなミラーレスカメラは、カメラのキタムラにおまかせください'
      );

    const state = reactive({
      installmentPaymentExampleList: [
        { productPrice: '148,500', numberOfDivisions: '48', monthlyPayment: '3,094' },
        { productPrice: '99,130', numberOfDivisions: '30', monthlyPayment: '3,305' }
      ],
      recommendedFeaturesList: [
        {
          href: 'https://shop.kitamura.jp/ec/special/camera/strap',
          img: 'https://shopimg.kitamura.jp/images/banner/1392.gif',
          alt: 'カメラストラップ特集'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/camera/dslr',
          img: 'https://shopimg.kitamura.jp/images/banner/1350.gif',
          alt: '今人気の一眼レフ＆ミラーレス特集'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/general/pet',
          img: 'https://shopimg.kitamura.jp/images/banner/1878.jpg',
          alt: 'ペット写真のステキな残し方'
        },
        {
          href: 'https://www.net-chuko.com/ec/page/feature/tell-me-chuuban',
          img: 'https://shopimg.kitamura.jp/images/banner/3498.jpg',
          alt: 'インスタなどSNSで人気！今こそ使いたいフィルムカメラ',
          target: 'blank'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: 'https://www.kitamura.jp/shasha/',
          img: 'https://shopimg.kitamura.jp/images/banner/8235.png',
          alt: 'ShaSha',
          target: 'blank'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント',
          target: 'blank'
        }
      ],
      navibtn: [
        { txt: 'ニコン Z fc ≫', href: '#caa' },
        { txt: 'オリンパス PEN E-P7 ≫', href: '#cab' },
        { txt: 'ソニー ZV-E10 ≫', href: '#cac' }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'きゅんとくる ミラーレス一眼カメラ',
          disabled: true
        }
      ],
      productJanCodeList: ['4549292109207'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↓ ランキング取得するJancode
      productJanCodeList2: ['4547410485950', '4549292200508', '4547410486421', '4549292189803', '4960759911421', '4548736133761'],
      productDetailList2: [] as Array<ProductDetail> // 結果格納用
      // ↑ 取得するJancode
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        console.log(productListResult);
        state.productDetailList = productListResult.items;
        // ランキング商品
        const productListResult2 = await ProductService.fetchProducts(state.productJanCodeList2, true);
        state.productDetailList2 = productListResult2.items;
      } catch (error) {
        console.error(error);
        state.productDetailList = [] as Array<ProductDetail>;
        state.productDetailList2 = [] as Array<ProductDetail>; // ランキング商品
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
