





























































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
//import Product from '@/components/product-list/product.vue';
import { noimage, formatPrice } from '@/logic/utils';
//import ProductListService from '@/logic/product-list.service';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
//import { ConditionItem } from '@/types/conditions';
//import { ProductItem } from '@/types/product-list';
//import { NEWER_SORT_LIST } from '@/constants/sort-list';
//import { DISPLAY_COUNT_LIST } from '@/constants/display-conut-list';
//import { SEARCH_STATE } from '@/constants/search-state';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
export default Vue.extend({
  name: 'aircleaner-compare',
  components: {
    recommendedFeatures: RecommendedFeatures,
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
    //product: Product
  },
  props: {},
  setup: (props, context) => {
    document.title = 'ここか違う！おすすめ加湿空気清浄機の最新モデルの選び方や性能の違いをご紹介 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '空気清浄機を選ぶとき、気になるポイントは人によってさまざま。使用するお部屋の雰囲気や用途によっても、最適な機種は異なります。そこで、キタムラではデザインや機能・性能、お手入れ方法、価格、特徴の５つに絞って、徹底比較を実施しました。'
      );
    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '人気のおすすめ加湿空気清浄機 シャープ・ダイキン製品を比較！',
          linkUrl: '/ec/special/kaden/airpurifier/aircleanercompare',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----
      selectedItem: 0,
      items: [
        { text: 'デザイン', icon: 'fa-check', link: '#hkk1' },
        { text: 'フィルター・加湿機能', icon: 'fa-check', link: '#hkk2' },
        { text: 'お手入れ方法', icon: 'fa-check', link: '#hkk3' },
        { text: '価格・コスト', icon: 'fa-check', link: '#hkk4' },
        { text: '特有の機能', icon: 'fa-check', link: '#hkk5' },
        { text: '商品一覧', icon: 'fa-check', link: '#item' }
      ],
      // ↓ ---- メイン商品 ----
      // 取得するJancode
      mainProductJanCode: [
        '4550556111386',
        '4573535311836',
        '4550556101363',
        '4573535366584',//↑デザイン比較
        '4550556111393',
        '4550556111386',
        '4573535366591',
        '4573535366201' //↑機種特有の特徴
      ],
      // 結果格納用
      mainProductList: [] as Array<ProductDetail>,
      // ↑ ---- メイン商品 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode 特集ID 217
      productJanCodeList: [
        '4550556111393',
        '4550556111386',
        '4550556101363',
        '4573535366584',
        '4573535366614',
        '4573535366607',
        '4573535366591',
        '4573535311836',
        '4573535366201',
        '4573535366218',
        '4573535366379'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
