























































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
// import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import { noimage } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'harunokouraku',
  components: {
    breadcrumbs: Breadcrumbs,
    // 'product-list-by-special-id': ProductListBySpecialId,
    recommendedFeatures: RecommendedFeatures,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  props: {},
  setup: (props, context) => {
    document.title = '春の行楽シーズンを満喫するためのアウトドア・ピクニック・旅行・スポーツにぴったりのアイテムを揃えました | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '春の行楽シーズンを満喫するために今から準備をはじめませんか？アウトドアグッズ、旅行・ドライブアイテム、スポーツ＆フィットネス用品を豊富に取り揃えた特集ページご紹介|カメラ専門店カメラのキタムラネットショップへおまかせください'
      );

    const state = reactive({
      isShow: false,
      // ↓ ---- 掲載期間 ----
      validFrom: '2025/03/17 10:00',
      validTo: '2025/05/07 10:00',
      // ↑ ---- 掲載期間 ----
      // 表示期間外メッセージ
      errorMsg: '',
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '春の行楽シーズンを満喫特集',
          linkUrl: '/ec/special/camera/harunokouraku',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----
      visible: false,
      index: 0, // default: 0 拡大時表示画像
      images: [
        process.env.VUE_APP_NET_SHOP_URL + '/images/pd/9d2/7a5/a3b/087/0f5/70c/110/7c4/e4f/813/43z/wyg/9en/u/L.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/harunokouraku/im_02.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/harunokouraku/im_03.jpg',
        process.env.VUE_APP_NET_SHOP_URL + '/ec/images2/special/camera/harunokouraku/im_04.jpg'
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/page/telephoneorder-info',
          img: 'https://shopimg.kitamura.jp/images/banner/1361.gif',
          alt: '電話注文承ります'
        },
        {
          href: '/ec/special/camera/lensfilter',
          img: 'https://shopimg.kitamura.jp/images/banner/1342.gif',
          alt: '効果・種類が分かる！レンズフィルター特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4905524796629'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      await fetchProduct();
      state.isShow = true;
    });
    // 画像を拡大して表示する
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };

    // 拡大画像を閉じる
    const handleHide = () => {
      state.visible = false;
    };

    return {
      ...toRefs(state),
      noimage,
      showImg,
      handleHide
    };
  }
});
