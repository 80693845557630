













































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ

export default Vue.extend({
  name: 'campaign',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    facebookAndTwitter: FacebookAndTwitter,
    categorymenulist: Categorymenulist
  },
  methods: {
    linkToOtherWindow(url: string | undefined) {
      window.open(url, '_blank');
    }
  },
  setup: (props, context) => {
    document.title = 'メーカーキャンペーン | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute('content', '今が買い時！メーカーキャンペーン！キャッシュバックやプレゼントなど、お得なメーカーキャンペーンをご紹介♪ | カメラのキタムラ');
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップトップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: true
        },
        {
          path: 'メーカーキャンペーン',
          disabled: true
        }
      ],
      campaignList: [
        // {
        //   campaignName: '',
        //  img: '',
        //  href: '',
        //  Contents: '',
        //  TargetPeriod: ''
        // },<!--▼-->
        {
          campaignName: 'キヤノン 春のキャッシュバック2025',
          img: 'https://shopimg.kitamura.jp/images/banner/9886.jpg',
          href: 'https://personal.canon.jp/product/campaign/camera-spring2025',
          Contents: '期間中、対象商品を購入し、応募した方に最大5万円をキャッシュバック(銀行振り込みによる)',
          TargetPeriod: '2025年3月14日(金)～2025年5月7日(水)'
        },
        {
          campaignName: 'パナソニック LUMIX S1RII 発売記念キャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9882.jpg',
          href: 'https://panasonic.jp/dc/campaign/s1rm2-release-cp.html',
          Contents: '期間中、対象商品をご購入・応募頂いたお客様全員に、DMW-BLK22を1個とDMW-BTC15を1台プレゼント',
          TargetPeriod: '2025年3月27日(木)～2025年5月11日(日)'
        },
        {
          campaignName: 'ソニー αスプリングキャッシュバックキャンペーン2025',
          img: 'https://shopimg.kitamura.jp/images/banner/9857.jpg',
          href: 'https://www.sony.jp/camera/campaign/cb25ilc_spring/',
          Contents: '期間中、対象商品を購入し、指定の手続きに従い不備なくご応募されたお客様に、対象商品毎に定めた金額をもれなくキャッシュバック',
          TargetPeriod: '2025年2月14日(金)～2025年5月7日(水)'
        },
        {
          campaignName: 'ソニー スナップ デイズ キャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9858.jpg',
          href: 'https://www.sony.jp/vlogcam/campaign/cb25spring_ZV/',
          Contents: '期間中、対象商品を購入し、指定の手続きに従い不備なくご応募されたお客様に、もれなく1万円をキャッシュバック',
          TargetPeriod: '2025年2月14日(金)～2025年5月7日(水)'
        },
        {
          campaignName: 'ソニー Cinema Line スプリングキャッシュバックキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9855.jpg',
          href: 'https://www.sony.jp/camera/campaign/cb25cl_spring/',
          Contents: '期間中、対象商品を購入し、指定の手続きに従い不備なくご応募されたお客様に、対象商品毎に定めた金額をもれなくキャッシュバック',
          TargetPeriod: '2025年2月14日(金)～2025年5月7日(水)'
        },
        {
          campaignName: 'Nikon Creators 応援スプリングキャンペーン 2025',
          img: 'https://shopimg.kitamura.jp/images/banner/9847.jpg',
          href: 'https://www.nikon-image.com/event/campaign/spring_2025/',
          Contents: '期間中、対象製品をご購入の上、所定の方法でご応募された方全員に、最大7万円をキャッシュバック',
          TargetPeriod: '2025年2月14日(金)～2025年5月7日(水)'
        },
        {
          campaignName: 'OMシステム OM-3 と一緒にどこまでもキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9840.jpg',
          href: 'https://jp.omsystem.com/campaign/c250206a/index.html',
          Contents: '期間中、単品購入で撮影に便利なアクセサリーをプレゼント、さらに対象レンズとの同時申込で『選べるe-GIFT』1万円分をプレゼント！',
          TargetPeriod: '2025年2月6日(木)～2025年4月9日(水)'
        },
        {
          campaignName: 'ニコン Z 9 Cfexpressメモリーカードセットキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9573.jpg',
          href: 'https://www.nikon-image.com/event/campaign/z_9_cf_campaign2024/',
          Contents: '期間中、Z 9をご購入された方に『CFexpress type Bメモリーカード660GB MC-CF660G』をプレゼント',
          TargetPeriod: '2024年4月26日(金)～なくなり次第終了'
        },
        {
          campaignName: '銘匠光学 ビューファインダープレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/8586.jpg',
          href: 'https://stkb.co.jp/info/?p=18267',
          Contents: '期間中、TTArtisan 21mm f/1.5 ASPH Mマウントレンズを購入でビューファインダーをプレゼント！※なくなり次第終了',
          TargetPeriod: '2021年9月1日(水)～なくなり次第終了'
        },
        {
          campaignName: 'バンガード 選べるプレゼントキャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9854.jpg',
          href: 'https://www.vanguardworld.jp/blogs/news/campaign-present2025',
          Contents: '期間中、対象商品をご購入・応募いただいた方全員にレインカバーもしくはポーチをプレゼント。さらに抽選で7名様にオリジナルパーカーが当たる！',
          TargetPeriod: '2025年2月14日(金)～2025年4月30日(水)'
        },
        {
          campaignName: 'EPSON 新年度コスパ・タイパde応援!キャンペーン',
          img: 'https://shopimg.kitamura.jp/images/banner/9841.jpg',
          href: 'https://www.epson.jp/ec/campaign/spring2025-cb',
          Contents: '期間中、対象商品をご購入・ご応募で最大5,000円分（電子マネー等）をキャッシュバック！',
          TargetPeriod: '2025年2月6日(木)～2025年5月11日(日)'
        },
        {
          campaignName: 'パナソニック レンジ IoT延長保証サービス',
          img: 'https://shopimg.kitamura.jp/images/banner/9204.jpg',
          href: 'https://panasonic.jp/range/service/iot-ext-warranty.html',
          Contents: 'レンジを専用アプリにつないで無料で2年間保証期間を延長',
          TargetPeriod: '2023年4月21日(金)～'
        },
        {
          campaignName: 'パナソニック 全自動ディーガ IoT延長保証サービス',
          img: 'https://shopimg.kitamura.jp/images/banner/9205.jpg',
          href: 'https://panasonic.jp/diga/service/iot-ext-warranty.html',
          Contents: 'レコーダーを専用アプリにつないで無料で2年間保証期間を延長',
          TargetPeriod: '2023年4月21日(金)～'
        },
        {
          campaignName: 'パナソニック オートクッカー IoT延長保証サービス',
          img: 'https://shopimg.kitamura.jp/images/banner/9203.jpg',
          href: 'https://panasonic.jp/cook/service/iot-ext-warranty.html',
          Contents: 'クッカーを専用アプリにつないぐと、無料で2年間保証期間を延長',
          TargetPeriod: '2023年4月21日(金)～'
        }
      ]
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
