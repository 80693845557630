




















































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'carousels',
  components: {},
  props: {
    carousels: {
      required: true
    },
    type: {
      type: Number,
      required: false,
      default: 1
    }
  },
  setup: () => {
    const state = reactive({});

    return {
      ...toRefs(state)
    };
  }
});
