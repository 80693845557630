
































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import MomoBtn from '@/components/common/special/momoBtn.vue';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ShoppingCredit from '@/components/common/special/shopping-credit.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'sportsday',
  components: {
    breadcrumbs: Breadcrumbs,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    momoBtn: MomoBtn,
    orangeBtn: OrangeBtn,
    priceAndPopular: PriceAndPopular,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    shoppingCredit: ShoppingCredit,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = 'パパ・ママ応援運動会特集！おすすめ一眼カメラ・ビデオ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'パパママ応援 運動会特集！カメラのキタムライチオシのビデオやデジカメと、おすすめ理由をまとめました。ビデオカメラ・一眼レフで運動会を上手に残しましょう♪'
      );
    const state = reactive({
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'パパ・ママ応援運動会特集！おすすめ一眼カメラ・ビデオ',
          disabled: true
        }
      ],
      programList: [
        { href: 'https://shop.kitamura.jp/ec/special/general/sportsday', txt: '運動会におすすめカメラ', subtxt: 'ビデオ・高倍率デジカメ' },
        { href: 'https://shop.kitamura.jp/ec/special/general/sportsday/photo-knowhow-1', txt: '撮影のコツ・機材お手入れ ', subtxt: 'HOW TO' },
        { href: 'https://www.kitamura-print.com/special/sportsday/', txt: '写真の残し方', subtxt: 'プリント・ビデオダビング' },
        { href: 'https://photobook.kitamura.jp/special/sportsday', txt: '写真のまとめ方', subtxt: 'フォトブック・アルバム' }
      ],
      naviList: [
        { src: '/ec/images2/special/general/sportsday/btn_topmirrorless.png', href: '#mirrorless' },
        { src: '/ec/images2/special/general/sportsday/btn_topdslr.png', href: '#dslr' },
        { src: '/ec/images2/special/general/sportsday/btn_toplens.png', href: '#lens' },
        { src: '/ec/images2/special/general/sportsday/btn_topcompact.png', href: '#compact' },
        { src: '/ec/images2/special/general/sportsday/btn_topvideo.png', href: '#video' }
      ],
      productList: [
        {
          id: 'mirrorless',
          src: '/ec/images2/special/general/sportsday/camera_a.jpg',
          href:
            'https://shop.kitamura.jp/pd/list_dtl.html?index=&searchbox=1&q=%E3%82%BD%E3%83%8B%E3%83%BC+%CE%B16400+%E3%83%80%E3%83%96%E3%83%AB%E3%82%BA%E3%83%BC%E3%83%A0%E3%83%AC%E3%83%B3%E3%82%BA%E3%82%AD%E3%83%83%E3%83%88&path=',
          title: '「リアルタイム瞳AF」と「リアルタイムトラッキング」でお子様の活躍をしっかり撮影できる',
          txt:
            'このカメラの「リアルタイム瞳AF」と「リアルタイムトラッキング」機能があれば、しっかりとお子様の瞳にピントを合わせ続けることができるので、「ピントが合わなかった…」といった失敗が少なくなるんです！',
          momotxt: 'ソニー α6400 \nダブルズームレンズキット ',
          orangetxt: 'キタムラおすすめ\nミラーレス・一眼レフ 一覧',
          orangehref: '/ec/special/general/sportsday/531'
        },
        {
          id: 'dslr',
          src: '/ec/images2/special/general/sportsday/camera_c.jpg',
          href: 'https://shop.kitamura.jp/ec/pd/4549292205336',
          title: 'カメラ初心者でも簡単！',
          txt: '被写体が画面内に入ると自動で検出してトラッキング。動きまわる被写体に追従してピントを合わせ続けるので、シャッターチャンスを逃しません♪',
          momotxt: 'キヤノン EOS R50\n ダブルズームキット',
          orangetxt: '運動会におすすめ\nミラーレス・一眼レフ 一覧',
          orangehref: '/ec/special/general/sportsday/531'
        },
        {
          id: 'lens',
          src: '/ec/images2/special/general/sportsday/camera_b.jpg',
          href: '/ec/pd/4960371006925',
          title: 'コレ1本で広角から望遠までカバーできる便利なレンズ',
          txt:
            'この競技はあのレンズで、次の競技はこのレンズ…と、プログラムとにらめっこする必要がありません。これ1本で運動会で考えられるすべてのシーンに対応できるんです♪ 全体の雰囲気からお子様のアップまでカンタンに撮影できます。',
          momotxt: 'タムロン 28-300mm F/4-7.1 Di III VC VXD\nソニーE用 (Model A074)',
          orangetxt: '運動会におすすめ\n交換レンズ 一覧',
          orangehref: '/ec/special/general/sportsday/532'
        },
        {
          id: 'compact',
          src: '/ec/images2/special/general/sportsday/camera_d.jpg',
          href: 'https://shop.kitamura.jp/pd/list_dtl.html?index=&searchbox=1&q=SX740&path=',
          title: 'できるだけ荷物を減らしたい！ とお考えの方におすすめ♪',
          txt:
            '超アップでの撮影もできる光学40倍ズーム搭載！ コンパクトなので持ち運びもラクチン。カメラが人物の顔を検出すると、自動でピントを合わせてくれる機能付きなので運動会撮影にピッタリ！',
          momotxt: 'キヤノン PowerShot\n SX740 HS',
          orangetxt: '運動会におすすめ\n高倍率ズームデジカメ 一覧',
          orangehref: '/ec/special/general/sportsday/485'
        },
        {
          id: 'video',
          src: '/ec/images2/special/general/sportsday/camera_e.jpg',
          href: 'https://shop.kitamura.jp/pd/list_dtl.html?index=&searchbox=1&q=CX680&path=',
          title: '運動会でのおすすめ機能 超強力手ブレ補正で滑らかな撮影',
          txt:
            '運動会撮影時はズームアップで撮ったり動きながらの撮影も多く、手ブレを起こしやすい場面がたくさん・・・ このビデオカメラならソニー自慢の超強力手ブレ補正で、ブレの少ない滑らかな撮影が可能。 さらに他のビデオカメラよりもワイドに撮影できるので、お昼ご飯を食べているお子様を、近距離で無理なく撮れるんです♪',
          momotxt: 'ソニー デジタルHDビデオ\nカメラレコーダー HDR-CX680',
          orangetxt: '運動会におすすめ\nビデオカメラ 一覧',
          orangehref: '/ec/special/general/sportsday/486'
        }
      ],
      convenientItemList: [
        {
          src: '/ec/images2/special/general/sportsday/cameray_c.png',
          alt: '場所を取らない1つで3役の三脚',
          href: '/ec/special/general/sportsday/1196',
          btntxt: 'ビデオ・デジカメ用三脚を見る'
        },
        {
          src: '/ec/images2/special/general/sportsday/videoy_b.png',
          alt: '三脚ねじ穴に取付ビデオグリップ',
          href:
            'https://shop.kitamura.jp/pd/list.html?searchbox=1&sort=number20%2Crank%2CScore&index=all&path=%E3%82%AB%E3%83%A1%E3%83%A9%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B5%E3%83%AA%E3%83%BC%E3%83%BB%E7%94%A8%E5%93%81&q=%E3%83%9C%E3%83%88%E3%83%A0%E3%82%B0%E3%83%AA%E3%83%83%E3%83%97&x=0&y=0',
          btntxt: 'ビデオ・デジカメ用グリップを見る'
        },
        {
          src: '/ec/images2/special/general/sportsday/cameray_a.png',
          alt: '32GB Class10 メモリーカード',
          href: '/ec/special/general/sportsday/2786',
          btntxt: 'SDメモリーカードを見る'
        }
      ],
      installmentPaymentExampleList: [
        { productPrice: '152,300', numberOfDivisions: '48', monthlyPayment: '3,173' },
        { productPrice: '76,800', numberOfDivisions: '20', monthlyPayment: '3,840' }
      ],
      btnList: [
        { txt: '一眼・ミラーレス一覧', href: '/ec/special/general/sportsday/531' },
        { txt: '高倍率ズームレンズ一覧', href: '/ec/special/general/sportsday/532' },
        { txt: '高倍率ズームデジカメ一覧', href: '/ec/special/general/sportsday/485' },
        { txt: 'ビデオカメラ一覧', href: '/ec/special/general/sportsday/486' },
        { txt: '三脚一覧', href: '/ec/special/general/sportsday/1196' },
        { txt: '記録メディア一覧', href: '/ec/special/general/sportsday/2786' }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/tripod',
          img: 'https://shopimg.kitamura.jp/images/banner/3253.gif',
          alt: 'おすすめ三脚の選び方'
        },
        {
          href: '/ec/special/kaden/bento',
          img: 'https://shopimg.kitamura.jp/images/banner/3807.jpg',
          alt: 'お弁当生活におすすめ商品キッチン商品大集合！'
        },
        {
          href: '/ec/special/kaden/bottle',
          img: 'https://shopimg.kitamura.jp/images/banner/1921.jpg',
          alt: 'おすすめの水筒・ステンレスボトルを集めました'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/special/page/telephoneorder-info',
          img: 'https://shopimg.kitamura.jp/images/banner/1361.gif',
          alt: '電話注文のご案内'
        },
        {
          href: 'https://www.kitamura-print.com/',
          img: 'https://shopimg.kitamura.jp/images/banner/341.jpg',
          alt: '超高画質プリント'
        }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4548736128644',
        '4545350055844',
        '4549292205336',
        '4549292214628',
        '4545350053956',
        '4960759902443',
        '4549292119183',
        '4549292179675',
        '4548736055612',
        '4960371006925',
        '4960371006840',
        '0085126750657',
        '0085126750756',
        '4545350054298',
        '4960759912893',
        '4960759906137',
        '4549292151411',
        '4549292186765',
        '4523052024092',
        '4907822082381'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
