







































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
//import Product from '@/components/product-list/product.vue';
import { noimage, formatPrice } from '@/logic/utils';
//import ProductListService from '@/logic/product-list.service';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
//import { ConditionItem } from '@/types/conditions';
//import { ProductItem } from '@/types/product-list';
//import { NEWER_SORT_LIST } from '@/constants/sort-list';
//import { DISPLAY_COUNT_LIST } from '@/constants/display-conut-list';
//import { SEARCH_STATE } from '@/constants/search-state';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'compact',
  components: {
    breadcrumbs: Breadcrumbs,
    categorymenulist: Categorymenulist
    //product: Product
  },
  props: {},
  setup: (props, context) => {
    document.title = '人気コンパクトデジカメのおすすめ機種ランキング | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        'コンパクトデジカメの人気機種をご紹介！望遠撮影が可能な高倍率ズームモデルや、キレイな背景ボケの写真撮影が楽しめるハイスペックモデルなど、ぜひコンデジ選びの参考にしてください☆カメラのキタムラネットショップ'
      );
    const state = reactive({
      // ↓ ---- 掲載期間 ----
      validFrom: '2021/01/22 00:00',
      validTo: '2099/01/25 23:59',
      // ↑ ---- 掲載期間 ----
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: 'https://shop.kitamura.jp/ec/special/sale-fair',
          disabled: false
        },
        {
          path: 'おすすめデジカメ（コンデジ）/デジタルカメラ特集',
          linkUrl: '/ec/special/camera/compact',
          disabled: false
        }
      ],
      // ↑ ---- パンくず情報 ----

      mainProductJanCode: ['4960759916631'], //高倍率デジカメ
      mainProductJanCode2: ['4549980789155'],
      mainProductJanCode3: ['4960759902443'],
      mainProductJanCode4: ['4548736106284'],
      mainProductJanCode5: ['4549292228090'],
      mainProductJanCode6: ['4549980888247'],
      mainProductJanCode7: ['4549292243567'],
      mainProductJanCode8: ['4961607446294'],
      mainProductJanCode9: ['4548736147058'],
      mainProductList: [] as Array<ProductDetail>,
      mainProductList2: [] as Array<ProductDetail>,
      mainProductList3: [] as Array<ProductDetail>,
      mainProductList4: [] as Array<ProductDetail>,
      mainProductList5: [] as Array<ProductDetail>,
      mainProductList6: [] as Array<ProductDetail>,
      mainProductList7: [] as Array<ProductDetail>,
      mainProductList8: [] as Array<ProductDetail>,
      mainProductList9: [] as Array<ProductDetail>,

      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4549292083064', '4549292083071', '4905524972535', '4549292167382'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
        // デジカメ
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        const mainResult2 = await ProductService.fetchProducts(state.mainProductJanCode2, true);
        state.mainProductList2 = mainResult2.items;
        const mainResult3 = await ProductService.fetchProducts(state.mainProductJanCode3, true);
        state.mainProductList3 = mainResult3.items;
        const mainResult4 = await ProductService.fetchProducts(state.mainProductJanCode4, true);
        state.mainProductList4 = mainResult4.items;
        const mainResult5 = await ProductService.fetchProducts(state.mainProductJanCode5, true);
        state.mainProductList5 = mainResult5.items;
        const mainResult6 = await ProductService.fetchProducts(state.mainProductJanCode6, true);
        state.mainProductList6 = mainResult6.items;
        const mainResult7 = await ProductService.fetchProducts(state.mainProductJanCode7, true);
        state.mainProductList7 = mainResult7.items;
        const mainResult8 = await ProductService.fetchProducts(state.mainProductJanCode8, true);
        state.mainProductList8 = mainResult8.items;
        const mainResult9 = await ProductService.fetchProducts(state.mainProductJanCode9, true);
        state.mainProductList9 = mainResult9.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
        // デジカメ
        state.mainProductList = [] as Array<ProductDetail>;
        state.mainProductList2 = [] as Array<ProductDetail>;
        state.mainProductList3 = [] as Array<ProductDetail>;
        state.mainProductList4 = [] as Array<ProductDetail>;
        state.mainProductList5 = [] as Array<ProductDetail>;
        state.mainProductList6 = [] as Array<ProductDetail>;
        state.mainProductList7 = [] as Array<ProductDetail>;
        state.mainProductList8 = [] as Array<ProductDetail>;
        state.mainProductList9 = [] as Array<ProductDetail>;
      }
    };

    onMounted(() => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
