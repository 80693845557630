// 購入手続き 登録情報タイプ

import { CodePaymentInfo, PaymentInfo } from '@/types/order-register';

// 受け取り方法
export const RECEIVE_TYPE = {
  SHOP: '1',
  HOME: '2'
};

// 受け取り店舗タイプ
export const DELIVERY_SHOP_TYPE = {
  /** 前回受け取った店舗 */
  LAST_RECEIVED_SHOP: '1',
  /** お気に入り店舗 */
  FAVORITE_SHOP: '2',
  /** 今回選んだ店舗 */
  NEW_SHOP: '3'
};

// お届け先タイプ
export const DELIVERY_ADDRESS_TYPE = {
  /** 自宅 */
  HOME: '0',
  /** 登録済住所 */
  REGISTERED_ADDRESS: '1',
  /** 銀行振込 */
  NEW_ADDRESS: '2'
};

// 支払い方法
export const PAYMENT_TYPE = {
  /** クレジットカード */
  CREDIT_CARD: '10',
  /** PayPay */
  PAY_PAY: '11',
  /** d払い */
  D_PAYPMENT: '12',
  /** auPay */
  AU_PAY: '13',
  /** メルペイ */
  MER_PAY: '14',
  /** 楽天ペイ */
  RAKUTEN_PAY: '15',
  /** 銀行振込 */
  BANK_TRANSFER: '20',
  /** アトカラ決済 */
  ATOKARA: '21',
  /** コンビニ決済 */
  CSV: '30',
  /** Tポイント */
  T_POINT: '40',
  /** Pay-easy決済 */
  PAY_EASY: '50',
  /** ショッピングクレジット */
  SHOPPING_CREDIT: '60',
  /** トクトク据置 */
  TOKUTOKU: '65',
  /** 代金引換 */
  CASH_ON_DELIVERY: '70',
  /** 店舗受取 */
  SHOP_PICKUP: '80'
};

// 支払い方法（表示用）
export const PAYMENT_INFO_LIST: PaymentInfo[] = [
  {
    id: 'CREDIT_CARD',
    types: [PAYMENT_TYPE.CREDIT_CARD],
    label: 'クレジットカード',
    text: 'クレジットカード番号と本人認証サービスにてお支払いいただけます',
    class: 'credit-card'
  },
  {
    id: 'ATOKARA',
    types: [PAYMENT_TYPE.ATOKARA],
    label: '後払い（アトカラ）',
    text: `WEB完結で携帯TEL・メアドのみでお支払いいただけます
 最大36回の分割払いもご利用可能です`,
    class: 'atokara'
  },
  {
    id: 'CODE',
    types: [PAYMENT_TYPE.PAY_PAY, PAYMENT_TYPE.D_PAYPMENT, PAYMENT_TYPE.AU_PAY, PAYMENT_TYPE.MER_PAY, PAYMENT_TYPE.RAKUTEN_PAY],
    label: 'コード決済',
    text: '各種コード決済がご利用いただけます',
    class: 'code'
  },
  {
    id: 'SHOPPING_CREDIT',
    types: [PAYMENT_TYPE.SHOPPING_CREDIT],
    label: 'ショッピングクレジット',
    text: '回までの分割手数料を当社負担でご利用いただけます',
    class: 'shopping-credit'
  },
  {
    id: 'BANK_TRANSFER',
    types: [PAYMENT_TYPE.BANK_TRANSFER],
    label: '銀行振込',
    text: '当社口座へのお振り込みにてお支払いいただけます',
    class: 'bank-transfer'
  },
  {
    id: 'TOKUTOKU',
    types: [PAYMENT_TYPE.TOKUTOKU],
    label: 'トクトク据置',
    text: '月々のお支払いを低く抑えてお支払いいただけます',
    class: 'tokutoku'
  },
  {
    id: 'SHOP_PICKUP',
    types: [PAYMENT_TYPE.SHOP_PICKUP],
    label: '店頭支払い',
    text: '店頭で、商品を受取時に支払い方法をお選びいただけます',
    class: 'shop-pickup'
  }
];

// コンビニ決済の支払先名
export const CSV_PAYMENT_TYPE_NAME_LIST = [
  {
    code: '10001',
    text: 'ローソン'
  },
  {
    code: '10002',
    text: 'ファミリーマート'
  },
  {
    code: '10005',
    text: 'ミニストップ'
  },
  {
    code: '00006',
    text: 'デイリーヤマザキ'
  },
  {
    code: '00007',
    text: 'セブンイレブン'
  }
];

// クレジットカード支払い方法
export const CARD_PAYMENT_METHOD = {
  // 一括払い
  PAY_IN_FULL: '1',
  // 分割払い
  PAY_IN_INSTALLMENTS: '2',
  // ボーナス一括払い
  PAY_IN_BONUS: '3',
  // リボ払い
  REVOLVING_CREDIT: '5'
};

// クレジットカード支払い方法（表示用）
export const CARD_PAYMENT_METHOD_LIST = [
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_FULL,
    label: '一括払い'
  },
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_INSTALLMENTS + '-3',
    label: '分割払い（３回）'
  },
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_INSTALLMENTS + '-5',
    label: '分割払い（５回）'
  },
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_INSTALLMENTS + '-6',
    label: '分割払い（６回）'
  },
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_INSTALLMENTS + '-10',
    label: '分割払い（１０回）'
  },
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_INSTALLMENTS + '-12',
    label: '分割払い（１２回）'
  },
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_INSTALLMENTS + '-15',
    label: '分割払い（１５回）'
  },
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_INSTALLMENTS + '-18',
    label: '分割払い（１８回）'
  },
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_INSTALLMENTS + '-20',
    label: '分割払い（２０回）'
  },
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_INSTALLMENTS + '-24',
    label: '分割払い（２４回）'
  },
  {
    id: CARD_PAYMENT_METHOD.PAY_IN_BONUS,
    label: 'ボーナス一括払い'
  },
  {
    id: CARD_PAYMENT_METHOD.REVOLVING_CREDIT,
    label: 'リボ払い'
  }
];

// ショッピングクレジットの信販会社（表示用）
export const SHOPPING_CREDIT_METHOD_LIST = [
  {
    id: '2',
    label: 'ジャックス',
    img: 'jaccs2404.png',
    displayLabelWithImage: true
  },
  // 未使用（APIParamとしても欠番）
  {
    id: '3',
    label: 'トクトク据置',
    img: '',
    displayLabelWithImage: true
  },
  // 現在は未使用（環境設定変数にて表示制御中。2021/01以降に表示予定）
  {
    id: '4',
    label: '三井住友カード',
    img: 'smbc_logo_moji2404.png',
    displayLabelWithImage: false //  画像が横長のため非表示にする
  },
  {
    id: '1',
    label: 'オリコ',
    img: 'orico2404.png',
    displayLabelWithImage: true
  }
];

export const CODE_PAYMENT_INFO_LIST: CodePaymentInfo[] = [
  {
    id: PAYMENT_TYPE.PAY_PAY,
    label: 'PayPay',
    description: 'PayPayの残高登録金額内でご利用いただけます'
  },
  {
    id: PAYMENT_TYPE.D_PAYPMENT,
    label: 'd払い',
    description: 'dポイント残高のほか、d払いに登録のクレジットカード、電話料金合算払い（ドコモ回線契約時のみ）にてお支払いいただけます。'
  },
  {
    id: PAYMENT_TYPE.AU_PAY,
    label: 'auPay',
    description:
      'お支払いはau PAY残高からとなります。au PAY残高が不足している場合はチャージが必要です。リアルタイムチャージを設定している場合はチャージの必要はございません。\nお支払いにはau PAYアプリが必要です'
  },
  {
    id: PAYMENT_TYPE.RAKUTEN_PAY,
    label: '楽天ペイ',
    description:
      'いつもの楽天IDとパスワードを使ってスムーズなお支払いが可能です。\n楽天ポイントが貯まる・使える！「簡単」「あんしん」「お得」な楽天ペイをご利用ください。\n※楽天ポイントが貯まるのは楽天カード・楽天ポイント・楽天キャッシュでのお支払いに限ります'
  },
  {
    id: PAYMENT_TYPE.MER_PAY,
    label: 'メルペイ',
    description:
      'お支払いはメルカリポイント、残高、メルペイスマート払い（対象者のみ）からとなります。 残高が不足している場合はチャージが必要です。\nメルカリアプリの事前のダウンロードおよび会員登録や設定を行っていただく必要があります。'
  }
];
