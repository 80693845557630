import { render, staticRenderFns } from "./photo-knowhow-1.vue?vue&type=template&id=854acafa&scoped=true&"
import script from "./photo-knowhow-1.vue?vue&type=script&lang=ts&"
export * from "./photo-knowhow-1.vue?vue&type=script&lang=ts&"
import style0 from "./photo-knowhow-1.vue?vue&type=style&index=0&id=854acafa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "854acafa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VCard,VCol,VContainer,VHover,VImg,VRow})
