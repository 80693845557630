


















































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import Urlcopy from '@/components/common/special/url-copy.vue';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue';
export default Vue.extend({
  name: 'travel',
  components: {
    breadcrumbs: Breadcrumbs,
    urlcopy: Urlcopy,
    watchInVideo: WatchInVideo,
    facebookAndTwitter: FacebookAndTwitter,
    recommendedFeatures: RecommendedFeatures,
    categorymenulist: Categorymenulist
  },
  setup: () => {
    document.title = '旅のお供に！バイヤー厳選おすすめ旅カメラ | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '今年はどこに旅行されますか？近場から遠方までその時々で感じた美しい景色や楽しい体験など、大切な旅の思い出を記憶と写真に残しておきましょう♪カメラのキタムラ カメラバイヤー厳選のミラーレス一眼やコンパクトデジカメ、更にカメラアクセサリーなどおすすめのポイントと共にご紹介いたします！'
      );

    const state = reactive({
      // パンくず情報
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '旅のお供に！バイヤー厳選おすすめ旅カメラ',
          disabled: true
        }
      ],
      // パンくず情報
      // ナビ
      naviList: [
        { naviItem: '動画撮影にもおすすめカメラ', href: '#ct01', txt: '動画を中心に撮影したい！そんな方におすすめカメラ' },
        { naviItem: '人気！360度撮影や防水カメラ', href: '#ct02', txt: '海や雪山。アウトドアで活躍するカメラを集めました' },
        { naviItem: '定番人気のミラーレスカメラ', href: '#ct03', txt: 'オシャレ、そして奇麗に写真を残したい方におすすめ' },
        { naviItem: '旅行には必須！おすすめアクセサリー', href: '#ct04', txt: '旅には欠かせない、アクセサリーを集めました' }
      ],
      // ナビ
      // 商品呼び出し
      mainProductJanCode: ['4546821923761', '6970357853922', '4545350053369', '8024221688375'],
      mainProductList: [] as Array<ProductDetail>,
      // 商品呼び出し
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature',
          img: 'https://shopimg.kitamura.jp/images/banner/1341.jpg',
          alt: '話題の新製品'
        },
        {
          href: '/ec/special/camera/compact',
          img: 'https://shopimg.kitamura.jp/images/banner/1986.jpg',
          alt: 'おすすめデジカメ/デジタルカメラ特集'
        },
        {
          href: '/ec/special/general/wirelessimageshare',
          img: 'https://shopimg.kitamura.jp/images/banner/2785.gif',
          alt: 'デジカメとスマホを無線でつないで写真を楽しもう'
        },
        {
          href: '/ec/special/camera/dslr',
          img: 'https://shopimg.kitamura.jp/images/banner/1350.gif',
          alt: 'おすすめのデジタル一眼レフ/ミラーレスカメラ特集'
        },
        {
          href: '/ec/special/camera/waterproof',
          img: 'https://shopimg.kitamura.jp/images/banner/1220.jpg',
          alt: 'おすすめ防水デジタルカメラ / ウェアラブルカメラ特集'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        }
      ]
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
