

























































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import { noimage, formatPrice } from '@/logic/utils';
import { ProductDetail } from '@/types/product';
import ProductService from '@/logic/product.service';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import SubText from '@/components/common/special/subtext.vue';
import AppearanceImage from '@/components/common/special/appearance-image.vue';
import Characteristic from '@/components/common/special/characteristic.vue';
import ExampleImages from '@/components/common/special/example-image.vue';
import ExpensiveTradeIn from '@/components/common/special/expensive-trade-in.vue';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import KitamuraService from '@/components/common/special/kitamura-service.vue';
import MomoBtn from '@/components/common/special/momoBtn.vue';
import NaviBtn from '@/components/common/special/navi-btn.vue';
import NaviBtn2 from '@/components/common/special/navi-btn2.vue';
import NaviBtn3 from '@/components/common/special/navi-btn3.vue';
import NaviBtn4 from '@/components/common/special/navi-btn4.vue';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import NoProductListBySpecialId from '@/components/special/common/no-product-list-by-special-id.vue';
import NoPriceAndPopular from '@/components/common/special/noprice-and-popular.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import PriceAndPurchase from '@/components/common/special/price-purchase.vue';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import ShaSha from '@/components/common/special/shasha.vue';
import ShoppingCredit from '@/components/common/special/shopping-credit.vue';
import TableOfContentsNavi from '@/components/common/special/table-of-contents-navi.vue';
import SpecificationsExamplesBtn from '@/components/common/special/specifications-examples-btn.vue';
import SplitContent from '@/components/common/special/split-content.vue';
import WatchInVideo from '@/components/common/special/watch-in-video.vue';
import ProductAppeal from '@/components/common/special/productAppeal.vue';
import Carousels from '@/components/common/special/carousels.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'sample-component4',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-list-by-special-id': ProductListBySpecialId,
    'no-product-list-by-special-id': NoProductListBySpecialId,
    topTitleImg: TopTitleImg,
    urlcopy: Urlcopy,
    appearanceImage: AppearanceImage,
    subText: SubText,
    characteristic: Characteristic,
    exampleImages: ExampleImages,
    expensiveTradeIn: ExpensiveTradeIn,
    facebookAndTwitter: FacebookAndTwitter,
    kitamuraService: KitamuraService,
    momoBtn: MomoBtn,
    naviBtn: NaviBtn,
    naviBtn2: NaviBtn2,
    naviBtn3: NaviBtn3,
    naviBtn4: NaviBtn4,
    orangeBtn: OrangeBtn,
    nopriceAndPopular: NoPriceAndPopular,
    priceAndPopular: PriceAndPopular,
    priceAndPurchase: PriceAndPurchase,
    recommendedFeatures: RecommendedFeatures,
    shasha: ShaSha,
    shoppingCredit: ShoppingCredit,
    tableOfContentsNavi: TableOfContentsNavi,
    specificationsExamplesBtn: SpecificationsExamplesBtn,
    splitContent: SplitContent,
    watchInVideo: WatchInVideo,
    productAppeal: ProductAppeal,
    carousels: Carousels,
    categorymenulist: Categorymenulist
  },

  setup: (props, context) => {
    const state = reactive({
      visible: false,
      index: 0, //popup
      // ↓ ---- パンくず ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: '最新デジカメ新製品',
          linkUrl: '/ec/special/camera/feature',
          disabled: false
        },
        {
          path: 'サンプルコンポーネント４',
          disabled: true
        }
      ],
      // ↓ ---- 特徴 ----
      characteristicList: [
        {
          subtitle: 'ボディ内5軸手ブレ補正機構搭載RFレンズ使用時最大8.0段の補正効果',
          contents: [
            'EOSシリーズで初めて、手ブレに連動して撮像素子を動かす補正機構をボディー内に採用。手ブレ補正機構が搭載されていないレンズでも手振れ補正が可能',
            'さらにIS（手振れ補正機構）搭載RFレンズなら最大8.0段分の手ブレ補正を実現。望遠撮影や手持ち撮影といった手ブレの発生しやすい撮影環境でも快適な撮影が可能',
            '動画撮影時には、ボディー内とレンズ内の手ブレ補正機構の協調制御に加え、動画電子ISの併用も可能で作品作りを強力にサポート'
          ]
        },
        {
          subtitle: '「デュアルピクセル CMOS AF II」による快適なAF性能',
          contents: [
            '新CMOSセンサーの高速信号読み出しと新映像エンジンの高速処理により、「デュアルピクセル CMOS AF II」としてAF性能が向上',
            '静止画撮影・エリア自動選択時、最大1053分割の縦横画面の最大約100％の測距エリアを実現。より速く、より⾼精度なAFが可能',
            '人物の瞳・顔・頭部検出に対応。「EOS R」と比較して、より小さな瞳や横顔の検出性能が向上。動物（犬/猫/鳥）の瞳・顔・全身検出にも対応'
          ]
        },
        {
          subtitle: '幅広いシーンにおける快適な撮影をサポートする通信性能や操作性',
          contents: [
            '一眼レフEOSにて好評だったマルチコントローラーの採用と、3つの電子ダイヤル搭載でスムーズな操作性を実現',
            '防じん・防滴構造を採用し、水滴や砂じんのカメラ内部への侵入を抑制。さらに外装には軽量かつ高剛性のポリカーボネート樹脂を採用し、信頼性と機動性を両立',
            'スマホなどの携帯端末へのWi-Fi/Bluetooth®による接続に対応。撮影後のデータのやり取りがよりスムーズに行えます'
          ]
        }
      ],
      // ↓ ---- 高値下取り ----
      expensiveTradeInList: {
        name: 'キヤノン EOS 5D Mark IV ボディ',
        href:
          'https://www.net-chuko.com/sell/item-list.do?axisType=category&axisCond=%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E4%B8%80%E7%9C%BC%E3%83%AC%E3%83%95&goodsname=4549292075748&_ga=2.222765533.714669828.1626591933-721903692.1626267770&pattern=1',
        nameImg: 'https://shopimg.kitamura.jp/images/pd/e6a/566/223/13d/322/7d3/e9e/d4c/92d/3ba/b4d/q99/qon/e/L.jpg',
        txt: 'キヤノン EOS R5 / EOS R6',
        tradeInImg: 'https://shop.kitamura.jp/ts_rsc/1354/images/bnr_shitadori_350-130.jpg',
        tradeInProducts: []
      },
      // ↓ ---- ナビボタン ----
      naviList: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: 'お得情報', href: '#price' },
        { naviItem: '作例', href: '#images' }
      ],
      // ↓ ---- ナビボタン 文言変更版----
      naviList2: [
        { naviItem: '特徴スペック', href: '#spec' },
        { naviItem: '機種比較', href: '#hikaku' },
        { naviItem: '価格', href: '#price2' },
        { naviItem: '作例', href: '#images2' }
      ],
      // ↓ ---- ナビボタン 文言変更版----
      naviList3: [
        { naviItem: 'iPhone 8', href: '#spec' },
        { naviItem: 'iPhone XR', href: '#hikaku' },
        { naviItem: 'iPhone XS', href: '#price2' },
        { naviItem: 'iPhone 11', href: '#images2' },
        { naviItem: 'ロレックス\nROLEX', href: '#price2' },
        { naviItem: 'オメガ\nOMEGA', href: '#price2' },
        { naviItem: 'タグホイヤー\nTAG HEUER', href: '#price2' },
        { naviItem: 'グランドセイコー\nGrandSeiko', href: '#price2' }
      ],
      naviList4: [
        { naviItem: 'iPhone 12 Pro', href: '#spec' },
        { naviItem: 'iPhone 12 Pro Max', href: '#hikaku' },
        { naviItem: 'iPhone 12', href: '#price2' },
        { naviItem: 'iPhone 12 mini', href: '#images2' },
        { naviItem: 'iPhone SE（第2世代）', href: '#price2' },
        { naviItem: 'iPhone 11 Pro', href: '#price2' },
        { naviItem: 'iPhone 11 Pro Max', href: '#price2' },
        { naviItem: 'iPhone 11', href: '#price2' },
        { naviItem: 'iPhone XS', href: '#price2' },
        { naviItem: 'iPhone XS Max', href: '#price2' },
        { naviItem: 'iPhone XR', href: '#price2' },
        { naviItem: 'iPhone X', href: '#price2' },
        { naviItem: 'iPhone 8', href: '#price2' },
        { naviItem: 'iPhone 8 Plus', href: '#price2' },
        { naviItem: 'iPhone 7', href: '#price2' },
        { naviItem: 'iPhone 7 Plus', href: '#price2' },
        { naviItem: 'iPhone 6s', href: '#price2' },
        { naviItem: 'iPhone 6s Plus', href: '#price2' }
      ],
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: [
        '4549292157345',
        '4549292157253',
        '4549292157512',
        '4549292157222',
        '4549292162813',
        '4549292162837',
        '4549292115710',
        '4549292115703',
        '4549292148381',
        '4549292115611',
        '4549292156263',
        '4549292168037',
        '4960999581569',
        '4960999581576',
        '4549292125405',
        '4960999530383',
        '4523052023392',
        '4523052023408',
        '4523052016639'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 価格・人気アクセサリー ----
      mainProductJanCode: ['4549292157345', '4549292157253', '4549292157512'],
      mainProductList: [] as Array<ProductDetail>,
      // ↓ ---- 比較 ----
      comparisonJanCodeList: ['4549292157345', '4549292157253', '4549292157512', '4548736108264', '4548736079625'],
      // 結果格納用
      comparisonDetailList: [] as Array<ProductDetail>,
      // ↑ ---- 比較 ----
      // ↓ ---- 話題の新製品バックナンバー＆おすすめの特集 ----
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/feature/backnumber',
          img: 'https://shopimg.kitamura.jp/images/banner/4924.png',
          alt: '話題の新製品バックナンバー',
          target: 'brank'
        },
        {
          href: '/ec/special/general/highly_recommended/2020/4722/',
          img: 'https://shopimg.kitamura.jp/images/banner/7619.png',
          alt: 'スタッフイチオシ！キヤノン RF70-200mm F2.8 L IS USM'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },

        {
          href: '/ec/special/general/tokutokukoukan',
          img: 'https://shopimg.kitamura.jp/images/banner/3778.gif',
          alt: 'トクトク交換'
        },
        {
          href: 'https://www.net-chuko.com/static/contents/sell/kakaku-hosyo.html',
          img: 'https://shopimg.kitamura.jp/images/banner/2439.gif',
          alt: 'トクトク買取',
          target: 'blank'
        }
      ],
      // ↓ ---- ショッピングクレジット ----
      installmentPaymentExampleList: [
        { productPrice: '84,910', numberOfDivisions: '20', monthlyPayment: '4,246' },
        { productPrice: '58,310', numberOfDivisions: '10', monthlyPayment: '5,831' }
      ],
      // ↓ ---- カルーセル(タイプ１) ----
      carousels: [
        'https://shop.kitamura.jp/ts_rsc/1350/images/ga01.jpg',
        'https://shop.kitamura.jp/ts_rsc/1350/images/ga02.jpg',
        'https://shop.kitamura.jp/ts_rsc/1350/images/ga03.jpg',
        'https://shop.kitamura.jp/ts_rsc/1350/images/ga04.jpg',
        'https://shop.kitamura.jp/ts_rsc/1350/images/ga05.jpg',
        'https://shop.kitamura.jp/ts_rsc/1350/images/ga06.jpg'
      ],
      // ↓ ---- カルーセル(タイプ２) ----
      carousels2: [
        { src: 'https://shop.kitamura.jp/ts_rsc/285/images/touit-slide_01.jpg', txt: 'FUJIFILM X-E1 × Carl Zeiss Touit 2.8/12 （ f/11 ISO/200 1/500秒 ）' },
        { src: 'https://shop.kitamura.jp/ts_rsc/285/images/touit-slide_02.jpg', txt: 'FUJIFILM X-Pro1 × Carl Zeiss Touit 2.8/12 （ f/16 ISO/200 4秒 ）' },
        { src: 'https://shop.kitamura.jp/ts_rsc/285/images/touit-slide_03.jpg', txt: 'FUJIFILM X-E1 × Carl Zeiss Touit 2.8/12 （ f/13 ISO/640 18秒 ）' },
        { src: 'https://shop.kitamura.jp/ts_rsc/285/images/touit-slide_04.jpg', txt: 'SONY NEX-7 × Carl Zeiss Touit 1.8/32 （ f/1.8 ISO/100 1/640秒 ）' },
        { src: 'https://shop.kitamura.jp/ts_rsc/285/images/touit-slide_05.jpg', txt: 'SONY NEX-7 × Carl Zeiss Touit 1.8/32 （ f/1.8 ISO/100 1/2500秒 ）' }
      ],
      // ↓ ---- カルーセル(タイプ3) ----
      carousels3: [
        { src: 'https://shop.kitamura.jp/ec/images2/guide/atokara/bn02-a.png', src2: 'https://shop.kitamura.jp/ec/images2/guide/atokara/bn02s-a.png', tit: 'タイトル', txt: '111111111111' },
        { src: 'https://shop.kitamura.jp/ec/images2/guide/atokara/bn02-b.png', src2: 'https://shop.kitamura.jp/ec/images2/guide/atokara/bn02s-b.png', tit: 'タイトル22222222', txt: '222222222222' }
      ],
      //popup
      imgs: [
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_01.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_02.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_03.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_04.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_05.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_06.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_07.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_08.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_09.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_010.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_011.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_012.jpg',
        'https://shop.kitamura.jp/ec/images2/special/camera/feature/nikon/z600f63vrs/pic_013.jpg'
      ]
    });
    //popup↓
    const showImg = (index: number) => {
      state.index = index;
      state.visible = true;
    };
    const handleHide = () => {
      state.visible = false;
    }; //popup↑
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // // メイン商品
        const mainResult = await ProductService.fetchProducts(state.mainProductJanCode, true);
        state.mainProductList = mainResult.items;
        // // 比較
        const comparisonResult = await ProductService.fetchProducts(state.comparisonJanCodeList, true);
        state.comparisonDetailList = comparisonResult.items;
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // メイン商品
        state.mainProductList = [] as Array<ProductDetail>;
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });

    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      showImg, //popup
      handleHide //popup
    };
  }
});
