import { render, staticRenderFns } from "./dbarai.vue?vue&type=template&id=14d1ff62&scoped=true&"
import script from "./dbarai.vue?vue&type=script&lang=ts&"
export * from "./dbarai.vue?vue&type=script&lang=ts&"
import style0 from "./dbarai.vue?vue&type=style&index=0&id=14d1ff62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d1ff62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VApp,VImg,VSheet})
