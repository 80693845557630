































































































































































































import Vue from 'vue';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import { ProductDetail } from '@/types/product';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import RecommendedFeatures from '@/components/common/special/recommended-features.vue';
import Urlcopy from '@/components/common/special/url-copy.vue';
import TopTitleImg from '@/components/common/special/top-title-img.vue';
import OrangeBtn from '@/components/common/special/orangeBtn.vue';
import PriceAndPopular from '@/components/common/special/price-and-popular.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'feature',
  components: {
    breadcrumbs: Breadcrumbs,
    recommendedFeatures: RecommendedFeatures,
    urlcopy: Urlcopy,
    topTitleImg: TopTitleImg,
    orangeBtn: OrangeBtn,
    priceAndPopular: PriceAndPopular,
    categorymenulist: Categorymenulist
  },
  setup: (props, context) => {
    document.title = '卒園・卒業アルバムや寄せ書きをかわいく作るコツ！手作りアルバム特集 | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '大切な人に、心を込めて贈りたい。卒業・卒園・入学・入園などの記念日や、転勤・退職などの節目に、思い出の写真を感謝の気持ちを込めて贈りましょう！手作りアルバムにおすすめの商品や作り方のコツをご紹介|カメラ専門店カメラのキタムラネットショップへおまかせください。'
      );
    const state = reactive({
      printServiceList: [
        {
          name: 'デザインコラージュプリント',
          src: '/ec/images2/special/camera/album/feature/150100-5b.jpg',
          txt:
            'デザイン性の高いテンプレートで、思い出がさらに輝きます。\nお気に入りの写真をギュッと詰めて、色紙や寄せ書きに使えばオシャレ感がUPします。\n（※プリントサービスのページへ移動します）',
          href: 'https://www.kitamura-print.com/print/design_collage/?_ga=2.162353916.1712661879.1629071621-279164427.1627285160'
        },
        {
          name: 'シャッフルプリント',
          src: '/ec/images2/special/camera/album/feature/shafful.jpg',
          txt:
            'たくさんの写真をまとめて1枚にまとめたいなら、「 シャッフルプリント 」がおすすめ！\nオシャレなフレームに入れて贈ったりするのも喜ばれます！\n（※プリントサービスのページへ移動します）',
          href: 'https://www.kitamura-print.com/print/wonder_shuffle/?_ga=2.140333811.1712661879.1629071621-279164427.1627285160'
        },
        {
          name: 'フォトプラスブック',
          src: '/ec/images2/special/camera/album/feature/photopurasu.jpg',
          txt:
            'いろいろ選べるキタムラオリジナルフォトブックは、写真が大きく残せるフォトブックです。\nカジュアルなソフトに対して、豪華なハードタイプもご用意しています。\n（※プリントサービスのページへ移動します）',
          href: 'https://photobook.kitamura.jp/photoplus'
        }
      ],
      recommendedFeaturesList: [
        {
          href: '/ec/special/camera/photomount',
          img: 'https://shopimg.kitamura.jp/images/banner/2456.gif',
          alt: '写真アルバム おすすめシリーズ紹介'
        },
        {
          href: '/ec/special/general/graduation_entrance',
          img: 'https://shop.kitamura.jp/images/banner/1929.gif',
          alt: '卒業・入学特集'
        },
        {
          href: '/ec/special/camera/album/feature/2519',
          img: 'https://shop.kitamura.jp/images/banner/3496.jpg',
          alt: '年賀状のデコレーションにおすすめアイテムをご紹介！写真ペン'
        },
        {
          href: '/ec/special/sale-fair',
          img: 'https://shopimg.kitamura.jp/images/banner/3808.gif',
          alt: 'セール・特集一覧'
        },
        {
          href: '/ec/list?category=&narrow1=&narrow2=&type=u&r=',
          img: 'https://shop.kitamura.jp/images/banner/4637.gif',
          alt: '新着中古'
        }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '手作りアルバム特集',
          disabled: true
        }
      ],
      productJanCodeList: [
        '4974214167704',
        '4974214175174',
        '4902205338192',
        '4902408338685',
        '4901770448428',
        '4902205235248',
        '4974214178083',
        '4974214171299',
        '4950486801522',
        '4902205233671',
        '4902205241577',
        '4550432208834',
        '4902205232421',
        '4902205233114',
        '4902205232445',
        '4902205236054',
        '4902205235248',
        '4901770448435'
      ],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
    });
    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
