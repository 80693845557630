


















































































































































































































import Vue from 'vue';
import { computed, reactive, toRefs } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
export default Vue.extend({
  name: 'tokutokukoukan',
  components: {
    breadcrumbs: Breadcrumbs,
    facebookAndTwitter: FacebookAndTwitter

  },
  setup: (props, context) => {
    document.title = 'デジカメ・レンズの買い替えは「トクトク交換」で下取り | カメラのキタムラネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '新しいデジカメ・カメラ・レンズの買い替えをご検討のお客さまへ「トクトク交換」を利用するとお得です！対象商品のご注文時にお手持ちの下取り交換品を選択すると特別価格で下取りいたします お支払いは差額分だけ！とってもお得にご購入できます。'
      );
    const state = reactive({
      seeNext: false,
      seeNextSp: false,
      navibtn: [
        { txt: 'トクトク交換とは', href: '#caa' },
        { txt: '5つの特徴', href: '#cab' },
        { txt: 'お申し込みの流れ', href: '#cac' }
      ],
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'デジカメ・レンズの買い替えは「トクトク交換」で下取り | カメラのキタムラネットショップ',
          disabled: true
        }
      ]
    });

    const changeSeeNext = () => {
      if (state.seeNext) {
        state.seeNext = false;
      } else {
        state.seeNext = true;
      }
    };
    const changeSeeNextSp = () => {
      if (state.seeNextSp) {
        state.seeNextSp = false;
      } else {
        state.seeNextSp = true;
      }
    };
    const linkToOtherWindow = (url: string | undefined) => {
      window.open(url, '_blank');
    };
    return {
      ...toRefs(state),
      noimage,
      formatPrice,
      changeSeeNext,
      changeSeeNextSp,
      linkToOtherWindow
    };
  }
});
