

















































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api'; //★カテゴリcomputed
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import { noimage, formatPrice } from '@/logic/utils';
import ProductService from '@/logic/product.service';
import { ProductDetail } from '@/types/product';
import FacebookAndTwitter from '@/components/common/special/facebook-twitter.vue';
import RecommendedSales from '@/components/common/special/recommended-sale.vue';
import ProductListBySpecialId from '@/components/special/common/product-list-by-special-id.vue';
import Categorymenulist from '@/components/common/special/categorymenulist.vue'; //★カテゴリ
export default Vue.extend({
  name: 'feature04',
  components: {
    breadcrumbs: Breadcrumbs,
    recommendedSales: RecommendedSales,
    facebookAndTwitter: FacebookAndTwitter,
    'product-list-by-special-id': ProductListBySpecialId,
    categorymenulist: Categorymenulist
  },
  props: {},
  setup: (props, context) => {
    document.title = '新生活応援アイテムフェア | カメラのキタムラ ネットショップ';
    document
      .querySelector<any>('meta[name="description"]')
      .setAttribute(
        'content',
        '新しい生活のスタートを応援します！カメラのキタムラ ネットショップでは、2月14日から3月31日までの期間限定で「新生活応援アイテムフェア」を開催中。引越しや新しい環境におすすめの家電や生活用品を、お得な価格でご用意しました。この機会に新生活の準備を整えましょう！'
      );
    const state = reactive({
      isShow: false,
      // ↓ ---- 掲載期間 ----
      validFrom: '2025/02/14 09:00',
      validTo: '2025/03/31 09:00',
      // ↑ ---- 掲載期間 ----
      // 表示期間外メッセージ
      errorMsg: '',
      recommendedSaleList: [
        {
          href: 'https://www.kitamura-print.com/video_copy/dvd_copy/shop.html',
          img: 'https://shopimg.kitamura.jp/images/banner/3838.png',
          alt: 'ビデオテープからのダビング最大35％OFF',
          target: 'blank'
        },
        {
          href: 'https://www.kitamura-print.com/campaign/volumediscount/',
          img: 'https://shopimg.kitamura.jp/images/banner/3561.jpg',
          alt: 'デジカメプリントが100枚以上で10％OFF、最大30％OFF',
          target: 'blank'
        },
        {
          href: 'https://www.kitamura.jp/service/glass-coating/',
          img: 'https://shopimg.kitamura.jp/images/banner/7493.jpg',
          alt: 'ガラスコーティング',
          target: 'blank'
        },
        {
          href: 'https://www.kitamura.jp/service/maintenance/',
          img: 'https://shopimg.kitamura.jp/images/banner/3842.jpg',
          alt: 'カメラのメンテナンスサービス',
          target: 'blank'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/reuse/watch',
          img: 'https://shopimg.kitamura.jp/images/banner/6207.png',
          alt: '中古時計販売',
          target: 'blank'
        },
        {
          href: 'https://shop.kitamura.jp/ec/special/reuse/smartphone',
          img: 'https://shopimg.kitamura.jp/images/banner/7495.png',
          alt: '中古スマホ',
          target: 'blank'
        }
      ],
      // ↓ ---- パンくず情報 ----
      breadcrumbs: [
        {
          path: 'ネットショップ',
          linkUrl: '/',
          disabled: false
        },
        {
          path: 'セール・特集一覧',
          linkUrl: '/ec/special/sale-fair',
          disabled: false
        },
        {
          path: '新生活応援アイテムフェア | 家族世帯 キッチン',
          linkUrl: '/ec/special/sale/feature04/870',
          disabled: true
        }
      ],
      // ↑ ---- パンくず情報 ----
      // ↓ ---- 価格・人気アクセサリー ----
      // 取得するJancode
      productJanCodeList: ['4905524796629'],
      // 結果格納用
      productDetailList: [] as Array<ProductDetail>
      // ↑ ---- 価格・人気アクセサリー ----
    });

    /**
     * 商品詳細を取得する
     */
    const fetchProduct = async () => {
      try {
        // 価格・人気アクセサリー
        const productListResult = await ProductService.fetchProducts(state.productJanCodeList, true);
        state.productDetailList = productListResult.items;
      } catch (error) {
        // 価格・人気アクセサリー
        state.productDetailList = [] as Array<ProductDetail>;
      }
    };
    onMounted(async () => {
      // ↓ ---- 掲載期間 ----
      if (state.validFrom && state.validTo) context.emit('validation-period', state.validFrom, state.validTo);
      // ↑ ---- 掲載期間 ----
      await fetchProduct();
      state.isShow = true;
    });
    return {
      ...toRefs(state),
      noimage,
      formatPrice
    };
  }
});
